<template>
    <div :class="`m-3 wrapper wrapper--border wrapper--${bu}`">
      <div class="text-center p-2">
        <h2>Resultaten per verkoper</h2>
        <span @click="previousYear"><i class="fas fa-arrow-left"></i></span>
        <span class="text-center m-2">Jaar: {{ year_number }}</span>
        <span @click="nextYear"><i class="fas fa-arrow-right"></i></span>
      </div>
      <Loading v-if="loading" />
      <b-card v-else no-body>
        <b-tabs
          content-class="mt-3"
          justified
          pills
          active-nav-item-class="active-nav-class"
          nav-class="nav-control-class"
        >
          <b-tab title="Jaar" active>
            <div class="p-2">
              <h2 class="text-center">Resultaten per jaar</h2>
              <ResultsPerSellerTable
                :table_data="franchise_results_per_year"
                :table_data_sum="franchise_results_per_year_sum"
                :table_style="210"
                :year_number="year_number"
                franchise="True"
              />
            </div>
          </b-tab>
          <b-tab title="Kwartaal" @click="loadQuarterData(franchise)">
            <Loading v-if="quarter_loading" />
            <div
              v-else
              v-for="(quarter, key) in franchise_results_per_quarter"
              :key="key"
              class="p-2"
            >
              <h2 class="text-center">{{ key }}</h2>
              <ResultsPerSellerTable
                :table_data="quarter.data"
                :table_data_sum="quarter.totals"
                :table_style="210"
                :year_number="year_number"
                franchise="True"
              />
            </div>
          </b-tab>
          <b-tab title="Maand" @click="loadMonthData(franchise)">
            <Loading v-if="month_loading" />
            <div
              v-else
              v-for="(months, key) in franchise_results_per_month"
              :key="key"
            >
              <div v-if="months.data" class="pt-2">
                <h2 class="text-center">{{ key }}</h2>
                <ResultsPerSellerTable
                  :table_data="months.data"
                  :table_data_sum="months.totals"
                  :table_style="210"
                  :year_number="year_number"
                  franchise="True"
                />
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
  </template>
  
  <script>
  import Loading from "@/components/Loading.vue";
  import request from "@/functions/request.js";
  import ResultsPerSellerTable from "@/components/ResultsPerSellerTable.vue";
  import moment from "moment";
  import { addTime, subtractTime, getYearNumber } from "@/functions/date.js";
  
  export default {
    props: ["franchise"],
    components: { Loading, ResultsPerSellerTable },
    data: () => ({
      loading: true,
      quarter_loading: true,
      month_loading: true,
      year_number: null,
      full_date: null,
      franchise_results_per_year: null,
      franchise_results_per_year_sum: null,
      franchise_results_per_quarter: null,
      franchise_results_per_month: null,
    }),
    created() {
      this.full_date = moment();
      this.getData(this.franchise);
    },
    watch: {
      franchise(newfranchise) {
        this.loading = true;
        this.quarter_loading = true;
        this.month_loading = true;
        this.getData(newfranchise);
      },
    },
    methods: {
      getData(franchise) {
        this.year_number = getYearNumber(this.full_date);
        request(`results-per-franchise/${franchise}/${this.year_number}`, "GET").then(
          ({ franchise_results_per_year }) => {
            this.franchise_results_per_year = franchise_results_per_year.data;
            this.franchise_results_per_year_sum = franchise_results_per_year.totals;
            this.loading = false;
          }
        );
      },
  
      loadQuarterData(franchise) {
        request(
          `results-per-franchise-per-quarter/${franchise}/${this.year_number}`,
          "GET"
        ).then(({ franchise_results_per_quarter }) => {
          this.franchise_results_per_quarter = franchise_results_per_quarter;
          this.quarter_loading = false;
        });
      },
  
      loadMonthData(franchise) {
        request(
          `results-per-franchise-per-month/${franchise}/${this.year_number}`,
          "GET"
        ).then(({ franchise_results_per_month }) => {
          this.franchise_results_per_month = franchise_results_per_month;
          this.month_loading = false;
        });
      },
  
      previousYear() {
        this.loading = true;
        this.quarter_loading = true;
        this.month_loading = true;
        this.full_date = subtractTime(1, this.full_date, "years");
        this.getData(this.franchise);
      },
  
      nextYear() {
        this.loading = true;
        this.quarter_loading = true;
        this.month_loading = true;
        this.full_date = addTime(1, this.full_date, "years");
        this.getData(this.franchise);
      },
    },
  };
  </script>